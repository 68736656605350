<template>
  <div>
    <v-row>
      <v-col>
        <v-toolbar flat dense>
          <div>
            {{ section.title }}
          </div>

          <v-spacer></v-spacer>

          <v-menu v-model="quantityEditMenu" :close-on-content-click="false">
            <template #activator="{ attrs, on }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                text
                small
                :disabled="$attrs.locked"
              >
                Ret antal
                <v-icon right> mdi-pound </v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-card-title>
                Antal af denne sektion
              </v-card-title>

              <v-card-text>
                <v-text-field
                  v-model="section.quantity"
                />
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn @click="update(); quantityEditMenu = false;">
                  Opdater
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>

          <v-menu v-model="editTitle" :close-on-content-click="false" width="600" offset-y>
            <template #activator="{ attrs, on }">
              <v-btn v-bind="attrs" v-on="on" text small>
                Ret navn
                <v-icon right> mdi-pencil-outline </v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-card-text>
                <v-text-field v-model="section.title" />
              </v-card-text>
              <v-card-actions>
                <v-btn text @click="editTitle = false">
                  Annuller
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn @click="update">
                  Gem
                  <v-icon right> mdi-content-save-outline </v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>

          <v-menu
            offset-y
          >
            <template #activator="{ attrs, on }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                text
                small
                :disabled="$attrs.locked"
              >
                Slet sektion
                <v-icon right> mdi-delete-outline </v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-card-title>
                Er du sikker?
              </v-card-title>
              <v-card-text>
                Materialer og arbejder under denne sektion vil også blive slettet.
              </v-card-text>
              <v-card-actions>
                <v-btn @click="deleteSection">
                  Ja, slet
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>

          <v-badge :content="messageCount" :value="messageCount" overlap>
            <v-btn
              :loading="loadingMessages"
              @click="showRightDrawer = true"
              text
              small
            >
              <v-icon small>
                mdi-message-outline
              </v-icon>
            </v-btn>
          </v-badge>
        </v-toolbar>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <SpecificationMaterialTable
          :items="section?.parts"
          :section="section"
          :locked="$attrs.locked"
          @update="update"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <SpecificationLaborTable
          :items="section?.labors"
          :section="section"
          :locked="$attrs.locked"
          @update="update"
        />
      </v-col>
    </v-row>

    <v-navigation-drawer
      v-model="showRightDrawer"
      temporary
      right
      app
      clipped
      width="600"
      class="pt-16"
    >
      <v-toolbar>
        <v-btn
          @click="showRightDrawer = false"        
        >
          Luk
        </v-btn>
      </v-toolbar>
      <ActivityLog
        title="Beskeder"
        :entries="messages"
        :relationId="this.section.id"
        relationKind="specification"
      />
    </v-navigation-drawer>
  </div>
</template>

<script>
import SpecificationLaborTable from '@/components/specification/SpecificationLaborTable.vue';
import SpecificationMaterialTable from '@/components/specification/SpecificationMaterialTable.vue';
import ActivityLog from '@/components/ActivityLog.vue';

export default {
  name: 'SpecificationSection',
  props: {
    spec: {
      type: Object,
      required: true
    }
  },
  components: {
    SpecificationLaborTable,
    SpecificationMaterialTable,
    ActivityLog
  },
  data() {
    return {
      editTitle: false,
      deleteMode: false,
      deleting: false,
      loadingMessages: false,
      messages: [],
      quantityEditMenu: false,
      section: {},
      showRightDrawer: false
    }
  },
  computed: {
    messageCount() {
      return this.messages.filter(x => x.type === 'Message').length;
    }
  },
  methods: {
    async update() {
      try {
        this.saving = true;

        const response = await this.$AuthService.api.patch('specification/section/edit', this.section);

        if (!response.data.success) throw 'Error updating section';

        this.$notifier.showSuccess({ message: 'Ændringer blev gemt' });
        this.editTitle = false;
        this.$emit('section:update');
      } catch (error) {
        console.error(error);
        this.$notifier.showError({ message: 'Ændringerne blev ikke gemt' });
      } finally {
        this.saving = false;
      }
    },
    async deleteSection() {
      try {
        const response = await this.$AuthService.api.delete(`specification/section/${this.section.id}`);

        if (!response.data.success) throw 'Error deleting section';

        this.$notifier.showSuccess({ message: 'Sektionen blev slettet' });

        const { pathMatch, relationId, specId } = this.$route.params;
        this.$router.replace(`${pathMatch}/${relationId}/specification/${specId}`);
        this.$emit('section:delete');
      } catch (error) {
        console.error(error);
        this.$notifier.showError({});
      }
    },
    getSectionFromRoute() {
      // Cast params to numbers
      const groupNumber = Number(this.$route.params.groupNumber);
      const sectionNumber = Number(this.$route.params.sectionNumber);

      if (!!groupNumber) {
        const groupIndex = this.spec.groups.findIndex(group => group.number === groupNumber);
        const sectionIndex = this.spec.groups[groupIndex].sections.findIndex(section => section.number === sectionNumber);

        return this.spec.groups[groupIndex].sections[sectionIndex];
      } else {
        const sectionIndex = this.spec.sections.findIndex(section => section.number === sectionNumber);
        return this.spec.sections[sectionIndex];
      }
    }
  },
  watch: {
    spec: {
      async handler() {
        this.section = this.getSectionFromRoute();
        this.messages = await this.$store.dispatch('messages/getMessages', [this.section.id]);
        this.$forceUpdate();
      }
    },
    '$route.params': {
      async handler(params) {
        if (!this.spec) return;

        this.section = this.getSectionFromRoute();
        this.messages = await this.$store.dispatch('messages/getMessages', [this.section.id]);
        this.$forceUpdate();
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
